@import '../../../style-params';

.temp-upgrade__content-container {
  width: 100%;
  max-width: 500px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.temp-upgrade__mixnpik-logo-container {
  align-self: center;
  margin: 16px;
  background-position: center top;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 8px;
}

.temp-upgrade__title {
  @include font-title-4();
  text-align: center;
  text-transform: uppercase;
}

.temp-upgrade__footer-title {
  @include font-title-7();
  text-align: left;
  color: $color-light-88;
  margin-top: 32px;
}

.temp-upgrade__footer-copy {
  margin-right: 8px;
  color: $color-light-88;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.temp-upgrade__footer-copy-entry {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  
}

.temp-upgrade__footer-copy-entry-icon {
  flex-direction: column;
}

.temp-upgrade__footer-copy-entry-text {
  margin-left: 16px;
  margin-top: 2px;
  flex-direction: column;
}

.temp-upgrade__footer-copy-entry-text-title {
  @include font-title-7();
  color: $color-light-88;
}

.temp-upgrade__footer-copy-entry-text-description {
  color: $color-light-70;
  font-size: 14px;
}




