@import '../_style-params';

.application__container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media (min-width: 992px) {
  .application__container {
    position: relative;
    width: 500px;
    height: 100%;
    margin: 0 auto 0 auto;
    overflow: hidden;
  }
}
.application__screens {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  // transition-property: left; - Value set within component
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}
