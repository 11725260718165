@import url('https://mixnpik.com/fonts/black-sans/style.css');
@import './_style-params.scss';

* {
  box-sizing: border-box;
}

body,
html,
.fullheight {
  height: 100%;
  min-height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  background: $color-background;
  background-color: $color-background;
  @include font-body;
  color: $color-light;
  line-height: normal;
}

a {
  color: $color-blue;
  text-decoration: none;
}

.windows_scroll-style {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #222222 #000000; /* scroll thumb and track */
}

.windows_scroll-style::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
  height: 12px; /* width of the entire scrollbar */
}

.windows_scroll-style::-webkit-scrollbar-track {
  background: rgb(0, 0, 0); /* color of the tracking area */
}

.windows_scroll-style::-webkit-scrollbar-thumb {
  background-color: #222222; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #000000; /* creates padding around scroll thumb */
}
