.bottom-sheet__container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.bottom-sheet__dialog {
  top: 100%;
  width: 100%;
  max-height: 80%;
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 50%;
  transform: translateX(-50%);
  animation-fill-mode: forwards;
}
.bottom-sheet__content {
  overflow-y: scroll;
}
