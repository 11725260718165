@import '../../../style-params';

.bottom-nav__container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: $color-footer;
  transition-property: bottom;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
}

.bottom-nav__item {
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $color-light;
  font-size: 12px;
  cursor: pointer;
  // transition: all 0.2s ease-in-out;

  &:hover {
    filter: brightness(70%) sepia(75%) saturate(400%) hue-rotate(355deg);
  }

  &--active {
    filter: brightness(70%) sepia(75%) saturate(400%) hue-rotate(355deg);
  }
  div {
    margin-top: 4px;
  }
}

.bottom-nav__badge,
.bottom-nav__badgenews {
  position: absolute;
  top: 9px;
  width: 16px;
  height: 16px;
  background-color: $color-yellow;
  border-radius: 50%;
  pointer-events: none;
}

.bottom-nav__badgenews {
  right: 290px;
}

.bottom-nav__badge {
  right: 56px;
}
