@import '../../../style-params';

.share__container {
  width: 100%;
  max-width: 600px;
  padding: 32px;
  color: $color-light;
  text-align: center;
  border-radius: 16px;
}

.share__title {
  @include font-title;
  border-radius: 4px;
  font-size: 24px;
  color: $color-light;
  text-align: center;
}

.share__copy {
  @include font-body;
  font-size: 16px;
  color: $color-light;
  margin-top: 16px;
  margin-bottom: 32px;
  text-align: center;
}

.share__buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 16px;
}

.share__button-icon {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
  width: 60px;
  height: 84px;
  background-repeat: no-repeat;
  font-size: 16px;
  &-whatsapp {
    background-image: url('./whatsapp.png');
  }
  &-messenger {
    background-image: url('./messenger.png');
  }
  &-facebook {
    background-image: url('./facebook.png');
  }
}
