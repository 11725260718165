.message__container {
  width: 90%;
  max-width: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5%;
  border-radius: 8px;
  animation-fill-mode: forwards;
}

@keyframes show-modal {
  from {
    top: 80%;
  }
  to {
    top: 0;
  }
}

@keyframes hide-modal {
  from {
    top: 0;
  }
  to {
    top: 80%;
  }
}
