@import '../../../style-params';

.get-native-group__container {
  width: 90%;
  max-width: 600px;
  min-height: 90%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5%;
  border-radius: 16px;
  background-color: #000000;
  animation-fill-mode: forwards;
  padding: 56px 32px 32px 32px;
  justify-content: space-between;
}

.get-native-group__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.get-native-group__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.get-native-group__title {
  @include font-title-4;
  text-align: center;
  margin-top: 24px;
}

.get-native-group__description {
  @include font-body;
  color: $color-light;
  text-align: center;
  margin-top: 48px;
  font-size: 18px;
}

.get-native-group__avatars {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 16px;
}

.get-native-group__names {
  @include font-body;
  align-self: flex-start;
  color: $color-light;
  font-size: 18px;
  margin-top: 8px;
  margin-bottom: 32px;
}

@keyframes show-achievement-modal {
  from {
    top: 80%;
  }
  to {
    top: 0;
  }
}

@keyframes hide-achievement-modal {
  from {
    top: 0;
  }
  to {
    top: 80%;
  }
}
