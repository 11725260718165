@import '../../../style-params';

.modalScreen__container {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color-background;
  color: #ffffff;
  animation-fill-mode: forwards;
  overflow-y: scroll;
}

.modalScreen__icon-container {
  align-self: flex-start;
  padding: 16px;
  width: 48px;
}

@keyframes show-modal {
  from {
    top: 100%;
  }
  to {
    top: 0;
  }
}

@keyframes hide-modal {
  from {
    top: 0;
  }
  to {
    top: 100%;
  }
}
