.overlay-container__container {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
}

.overlay-container__glass {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background-color: #000000aa;
  animation-fill-mode: forwards;
}

@keyframes show-glass {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes hide-glass {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
