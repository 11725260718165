@import '../../../style-params';

.upload-image-selector__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 24px;
}
