@import '../../../style-params';

.app-download__content-container {
  width: 100%;
  max-width: 500px;
  flex: 1;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  overflow-y: scroll;
}

.app-download__title {
  @include font-title-4();
  text-align: center;
  min-height: 56px;
  margin-bottom: 16px;
}

.app-download__screen-shot-container {
  flex: 1;
  margin-left: 16px;
  margin-right: 16px;
  background-position: center top;
  background-size: contain;
  background-repeat: no-repeat;
}

.app-download__footer-copy {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 32px;
  margin-bottom: 32px;
  color: $color-light-88;
  font-size: 14px;
  white-space: pre-wrap;
}
