@import '../../../style-params';

.simple-message__title {
  @include font-title-4-uppercase;
  text-align: center;
  border-radius: 4px;
  font-size: 22px;
  color: $color-light;
  pointer-events: none;
  user-select: none;
}

.simple-message__copy {
  @include font-body;
  margin: 24px 0 16px 0;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: $color-light;
  pointer-events: none;
  user-select: none;
}

.simple-message__buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
}
