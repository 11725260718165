@import '../../../style-params';

.get-native-challenges__gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
}

.get-native-challenges__top-icons-container {
  top: 0;
  width: 100%;
  padding: 8px;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
